import
{
    COMPLETE_LOADING,
    COMPLETE_SUCCESS,
    COMPLETE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewProtectedItemService } from "services/createNewProtectedItemService";
import { completeAuthEndpoint } from "api/apiUrls";

export const completeAuthAction = (callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: COMPLETE_LOADING, payload: true});
        const response = await createNewProtectedItemService(completeAuthEndpoint, {}, "json");
        dispatch({type: COMPLETE_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }
    catch (err) {
        dispatch({type: COMPLETE_FAILURE, payload: err});
    }
    finally {
        dispatch({type: COMPLETE_LOADING, payload: false});
    }
}