import
{
    COMPLETE_LOADING,
    COMPLETE_SUCCESS,
    COMPLETE_FAILURE
}
from "redux/types";

const initialState = { 
    completeAuthLoading: false,
    completed: null,
    errors: null
}

export const completeAuthReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case COMPLETE_LOADING :
            return {...state, completeAuthLoading: action.payload};
        case COMPLETE_SUCCESS :
            return {...state, completed: action.payload};
        case COMPLETE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}